<ng-template #buttonContent>
  <ng-content></ng-content>
</ng-template>

<a
  *ngIf="!link; else linkTmp"
  [class]="'icon-button ' + buttonStyle"
  [class.disabled]="disabled"
  [class.thin]="thin"
  [class.ico-default]="!size">
  <span class="icon">
    <cl-icon
      *ngIf="icon; else empty"
      [icon]="icon"
      [size]="size"></cl-icon>
    <ng-template #empty></ng-template>
  </span>
  <span class="button-text">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </span>
</a>

<ng-template #linkTmp>
  @if (internalRedirect) {
    <a
      [class]="'icon-button ' + buttonStyle"
      [class.disabled]="disabled"
      [class.thin]="thin"
      [routerLink]="link"
      [target]="openInNewTab ? '_blank' : '_self'"
      [class.ico-default]="!size">
      <span class="icon">
        <cl-icon
          *ngIf="icon; else empty"
          [icon]="icon"
          [size]="size"></cl-icon>
        <ng-template #empty></ng-template>
      </span>
      <span class="button-text">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </span>
    </a>
  } @else {
    <a
      [class]="'icon-button ' + buttonStyle"
      [class.disabled]="disabled"
      [class.thin]="thin"
      [href]="link"
      [target]="openInNewTab ? '_blank' : '_self'"
      [class.ico-default]="!size">
      <span class="icon">
        <cl-icon
          *ngIf="icon; else empty"
          [icon]="icon"
          [size]="size"></cl-icon>
        <ng-template #empty></ng-template>
      </span>
      <span class="button-text">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </span>
    </a>
  }
</ng-template>
